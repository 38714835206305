<template>
  <div class="takeouts">
    <NavBar />
    <v-container fluid>
      <h1>Точки выдачи</h1>
      <v-col v-if="this.checkPermission([128])">
        <v-btn @click="dialog = true" color="#e21a1a" class="white--text"><v-icon left>mdi-plus</v-icon>Добавить точку выдачи</v-btn>
      </v-col>
      <V-data-table :headers="headers" :items="takeouts" disable-pagination hide-default-footer>
        <template v-slot:item.nameFullRu="{ item }">
          <tr>
            <td>
              <v-text-field v-model="item.nameFullRu" background-color="transparent" flat hide-details="auto" placeholder="Введите наименование" solo />
            </td>
          </tr>
        </template>
        <template v-slot:item.onlyCorpo="{ item }">
          <tr>
            <td>
              <v-select
                width="100"
                v-model="item.onlyCorpo"
                :items="isActivePropertyList()"
                background-color="transparent"
                flat
                hide-details="auto"
                placeholder=""
                solo
                color="#e21a1a"
              />
            </td>
          </tr>
        </template>
        <template v-slot:item.disabledBySuperior="{ item }">
          <tr>
            <td>
              <v-select
                max-width="100"
                v-model="item.disabledBySuperior"
                :items="isActivePropertyList()"
                background-color="transparent"
                flat
                hide-details="auto"
                placeholder=""
                solo
                color="#e21a1a"
              />
            </td>
          </tr>
        </template>
        <template v-slot:item.station="{ item }">
          <td>
            <tr>
              <span>{{ item.station.nameExpFullRu }}</span>
            </tr>
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn @click="editTakeoutClick(item)" :disabled="itemStatus(item)" color="#e21a1a" text>
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
      </V-data-table>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-form ref="form" @submit.prevent="checkInput">
            <v-card-title>Новая точка выдачи</v-card-title>
            <v-card-text>
              <v-text-field
                class="my-3"
                v-model="newTakeout.nameFullRu"
                :rules="validationRules.notEmpty.concat(validationRules.nameForm)"
                outlined
                dense
                label="Название"
              ></v-text-field>
              <v-select
                class="my-3"
                v-model="newTakeout.stationToken"
                :items="stationsList"
                item-text="nameExpFullRu"
                item-value="token"
                :rules="validationRules.notEmpty"
                outlined
                dense
                label="Станции"
              ></v-select>
            </v-card-text>
            <v-card-actions class="d-flex flex-wrap">
              <v-btn class="white--text my-1 ml-auto" type="submit" depressed color="#e21a1a" @click="addTakeoutClick">СОХРАНИТЬ</v-btn>
              <v-btn text class="mx-2 text-none my-1" color="grey darken-3" @click="dialog = false">ОТМЕНА</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <div class="notification">
        <Notification />
      </div>
    </v-container>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue';
import { mapActions, mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import Notification from '@/components/Notification';
import Helper from '@/helpers/reference/reference.helper';

export default {
  data() {
    return {
      newTakeout: {
        nameFullRu: '',
        stationToken: ''
      },
      validationRules: {
        notEmpty: [(v) => !!v || 0 || 'Обязательное поле'],
        nameForm: [
          (value) => {
            const pattern = /[ЁёА-я,A-Za-z .-]+$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ]
      },
      dialog: false
    };
  },
  components: { NavBar, Notification },
  computed: {
    headers() {
      return [
        { text: 'Название', value: 'nameFullRu' },
        { text: 'Корпоративная', value: 'onlyCorpo' },
        { text: 'Заблокирована', value: 'disabledBySuperior' },
        { text: 'Возкал', value: 'station' },
        { text: '', value: 'actions' }
      ];
    },
    takeouts() {
      if (!this.takeoutsData) return [];
      return JSON.parse(JSON.stringify(this.takeoutsData));
    },
    ...mapGetters('admin', ['stationsList']),
    ...mapGetters('auth', ['userPerm']),
    ...mapGetters('takeouts', ['takeoutsData']),
    ...mapGetters('takeouts', ['result']),
    ...mapGetters('takeouts', ['errorMessage'])
  },
  methods: {
    checkPermission(value) {
      return this.userPerm.find((el) => value.includes(el)) !== undefined;
    },
    checkInput() {
      return true;
    },
    fetchTakeouts() {
      return this.fetchPermissionTakeoutsList();
    },
    fetchStations() {
      return this.fetchStationsList();
    },
    isActivePropertyList() {
      return ReferenceHelper.getActiveProperty();
    },
    itemStatus(item) {
      let defaultTag = this.takeoutsData.find((takeout) => takeout.token === item.token);
      let status = true;
      for (let prop in item) {
        if (typeof item[prop] !== 'object' && item[prop] !== defaultTag[prop]) {
          status = false;
        }
      }
      return status;
    },
    getBooleanValue(value) {
      return Helper.getActiveName(value);
    },
    addTakeoutClick() {
      if (this.$refs.form.validate()) {
        this.createTakeout(this.newTakeout)
          .then(() => {
            if (this.result) {
              this.fetchTakeouts();
              this.closeDialog();
              this.showNotification('Точка выдачи добавлена', 'success', '2000', 'mdi-check');
            }
          })
          .catch(() => {
            this.closeDialog();
            this.showNotification(`Возникла ошибка: ${this.errorMessage}`, 'error', '5000', 'mdi-alert-circle-outline');
          });
      }
    },
    editTakeoutClick(item) {
      this.editTakeout(item).then(() => {
        this.fetchTakeouts();
        this.closeDialog();
        this.showNotification('Точка выдачи отредактирована', 'success', '2000', 'mdi-check');
      });
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    closeDialog() {
      this.dialog = false;
      this.newTag.nameRu = '';
      this.newTag.stationToken = '';
    },
    ...mapActions('takeouts', ['fetchPermissionTakeoutsList']),
    ...mapActions('admin', ['fetchStationsList']),
    ...mapActions('takeouts', ['createTakeout']),
    ...mapActions('takeouts', ['editTakeout']),
    ...mapActions('notification', ['showSnack'])
  },
  mounted() {
    this.fetchStations();
    this.fetchTakeouts();
  }
};
</script>
